import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, ResponsiveContext } from 'grommet'
import { includes } from 'lodash/fp'

import mediaTemplates from '../lib/mediaTemplates'
import getBase from '../lib/getBaseVisUnits'

import envelopeSvg from '../images/envelope.svg'

const Letter = styled(Box)`
  background: #f2f2f2;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.2);
  position: relative;
  width: ${p => p.w * getBase('large')}px;
  height: ${p => p.h * getBase('large')}px;
  min-height: ${p => p.h * getBase('large')}px;
  top: ${p => (p.y || 0) * getBase('large')}px;
  right: ${p => (p.x || 0) * getBase('large')}px;
  ${mediaTemplates.medium`
    width: ${p => p.w * getBase('medium')}px;
    height: ${p => p.h * getBase('medium')}px;
    min-height: ${p => p.h * getBase('medium')}px;
    top: ${p => (p.y || 0) * getBase('medium')}px;
    right: ${p => (p.x || 0) * getBase('medium')}px;
  `}
  ${mediaTemplates.small`
    width: ${p => p.w * getBase('small')}px;
    height: ${p => p.h * getBase('small')}px;
    min-height: ${p => p.h * getBase('small')}px;
    top: ${p => (p.y || 0) * getBase('small')}px;
    right: ${p => (p.x || 0) * getBase('small')}px;
  `}
  z-index: ${p => p.z || 0};
`

const EnvelopeImg = styled.img`
  position: relative;
  z-index: ${p => p.z || 0};
  max-width: ${p => p.w * getBase('large')}px;
  height: ${p => p.h * getBase('large')}px;
  min-height: ${p => p.h * getBase('large')}px;
  top: ${p => (p.y || 0) * (getBase('large') / 2)}px;
  margin-top: ${p => (p.y || 0) * (getBase('large') / 2.8)}px;
  right: ${p => (p.x || 0) * getBase('large')}px;
  ${mediaTemplates.medium`
    max-width: ${p => p.w * getBase('medium')}px;
    height: ${p => p.h * getBase('medium')}px;
    min-height: ${p => p.h * getBase('medium')}px;
    top: ${p => (p.y || 0) * (getBase('medium') / 2)}px;
    margin-top: ${p => (p.y || 0) * (getBase('medium') / 2.8)}px;
    right: ${p => (p.x || 0) * getBase('medium')}px;
  `}
  ${mediaTemplates.small`
    max-width: ${p => p.w * getBase('small')}px;
    height: ${p => p.h * getBase('small')}px;
    min-height: ${p => p.h * getBase('small')}px;
    top: ${p => (p.y || 0) * (getBase('small') / 2)}px;
    margin-top: ${p => (p.y || 0) * (getBase('small') / 2.8)}px;
    right: ${p => (p.x || 0) * getBase('small')}px;
  `}
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
`

const Container = styled(Box)`
  align-items: center;
`

const smallSizes = ['xsmall', 'small']

const VisualPostcards = ({ justify }) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Container
        fill
        style={{ position: 'relative' }}
        margin={includes(size, smallSizes) ? { top: 'large' } : 'none'}
        pad={{ horizontal: 'small' }}
        justify={justify ? justify : 'start'}
      >
        <Letter size={size} x={0} y={0} w={8.5} h={11} z={0} />
        <EnvelopeImg src={envelopeSvg} x={1.3} y={-8} w={8.5} z={1} />
      </Container>
    )}
  </ResponsiveContext.Consumer>
)

VisualPostcards.propTypes = {
  justify: PropTypes.string,
}

export default VisualPostcards
